import React from "react";

import DefaultLayout from "../../layouts/default";

const Portrait = () => (
  <DefaultLayout title="Portrait">
    <div className="orange portrait">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li className="is-active">
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productText">
              <span className="line">WigaSoft</span>
              <span className="line"> – die Firma</span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a className="is-active" href="/portrait/">
                Übersicht
              </a>
            </li>
            <li>
              <a href="/portrait/lehrlinge/">Lernende</a>
            </li>
            <li>
              <a href="/portrait/partner/">Partner</a>
            </li>
            <li>
              <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
            </li>
            <li>
              <a href="/portrait/kontakt/">Kontakt</a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top has-padding-bottom">
            <div className="wrapper">
              <h1 className="text-centered">Über 35 Jahre Verbundenheit</h1>
            </div>
          </section>
        </div>

        <section className="wrapper">
          <div className="portraitFlex">
            <div>
              <img
                className="portraitLogos specialSwanLogo"
                src="/assets/images/15_Jahre2x.png"
              />
            </div>
            <div>
              <img
                className="portraitLogos"
                src="/assets/images/20_Jahre.svg"
              />
            </div>
            <div>
              <img
                className="portraitLogos"
                src="/assets/images/25_Jahre.svg"
              />
            </div>
            <div>
              <img
                className="portraitLogos"
                src="/assets/images/30_Jahre.svg"
              />
            </div>
            <div>
              <img
                className="portraitLogos"
                src="/assets/images/35_Jahre.svg"
              />
            </div>
          </div>
        </section>

        <div className="wrapper">
          <section
            style={{ position: "relative" }}
            className="has-padding-top has-padding-bottom"
          >
            <div className="wrapper">
              <p className="lead text-centered">
                Die WigaSoft AG wurde 1987 in St. Gallen gegründet und ist seit
                1993 auf Lösungen für das Gesundheitswesen spezialisiert. Heute
                entlastet unsere breit abgestützte Produkte- und
                Dienstleistungspalette in den Bereichen der Patienten- und
                Bewohnerdokumentation sowie der Leistungserfassung und
                -auswertung die Ärzte, das Pflegepersonal und die
                Klinik-/Heimleitung.
              </p>
            </div>
          </section>

          <section>
            <div className="wrapper has-padding-bottom">
              <div className="row">
                <div className="column one-half wsPaddingResp-no-mobile-right">
                  <p className="fliesstext2 has-padding-bottom">
                    Als Software-Entwickler im Gesundheitswesen sehen wir unsere
                    Aufgabe nicht nur in der Programmierung. Genauso wichtig
                    sind der regelmässige Dialog und der Erfahrungsaustausch mit
                    anderen. Der enge Kontakt mit Ärzten, leitenden Angestellten
                    und Pflegepersonal ermöglicht die stetige Verbesserung
                    unserer Produkte in Bezug auf den Leistungsumfang, die
                    Benutzerfreundlichkeit und natürlich die Praxistauglichkeit.
                    Deshalb beinhaltet jedes WigaSoft-Modul die Erfahrung und
                    das Wissen ausgewiesener Profis. Darüber hinaus suchen wir
                    aktiv die Zusammenarbeit mit anderen Spezialisten aus der
                    Softwarebranche.
                  </p>
                </div>
                <div className="column one-half has-border-left wsPaddingResp-no-mobile-left">
                  <p className="fliesstext2 has-padding-bottom">
                    Die etablierte Schnittstelle «ALIS» wurde zum Beispiel auf
                    Initiative der WigaSoft AG, zusammen mit anderen
                    Softwarefirmen aus dem Gesundheitswesen, entwickelt. Heute
                    garantiert ALIS den reibungslosen Datenaustausch zwischen
                    den Softwarepaketen der verschiedenen Hersteller und der
                    zentralen Abrechnung. Profitiert haben schlussendlich alle:
                    Unsere Kunden erfreuen sich an der hohen
                    Investitionssicherheit der Software-Lösungen, während sich
                    die Software-Firmen auf einen zuverlässigen Standard stützen
                    können. Kurz, der Blick in die Zukunft gehört für uns zum
                    Alltag.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section>
          <div className="wrapper has-padding-bottom">
            <div className="wrapperFlex">
              <div className="cContainer smallcCPadding flexItemVertSpacing is-background-color-orange is-color-white">
                <h4 className="smallStaticPadding-bottom">
                  Unsere Mitarbeiter
                </h4>
                <ul className="ulIndent">
                  <li>sind findige Software-Entwickler</li>
                  <li>
                    sind kompetente Kundenberater, die unsere Softwarelösungen
                    und deren Anwendung in der Hardware Umgebung unserer Kunden
                    kennen
                  </li>
                  <li>sind unkomplizierte, effiziente Sachbearbeiter</li>
                  <li>
                    haben Freiräume für die Arbeitsgestaltung und die notwendige
                    Weiterbildung
                  </li>
                  <li>
                    setzen sich mit Freude und Begeisterung für den nachhaltigen
                    Erfolg unseres Unternehmens ein
                  </li>
                </ul>
              </div>
              <div className="cContainer smallcCPadding flexItemVertSpacing is-background-color-orange is-color-white">
                <h4 className="smallStaticPadding-bottom">
                  Unsere Kunden schätzen die Zusammenarbeit,
                </h4>
                <ul className="ulIndent">
                  <li>
                    weil wir Trends in der Informationstechnologie und in der
                    Pflege erkennen und mithelfen Standards zu setzen
                  </li>
                  <li>
                    weil wir es gewohnt sind, Arbeitsabläufe zu organisieren und
                    lösungsorientiert zu arbeiten
                  </li>
                  <li>weil wir deren Ausbildung und Betreuung gewährleisten</li>
                </ul>
              </div>
              <div className="cContainer smallcCPadding is-background-color-orange is-color-white">
                <h4 className="smallStaticPadding-bottom">Unser Unternehmen</h4>
                <ul className="ulIndent">
                  <li>
                    soll übersichtlich und einfach organisiert bleiben und dazu
                    beitragen, die Leistungsfähigkeit unserer Kunden wesentlich
                    zu steigern
                  </li>
                  <li>
                    arbeitet nachhaltig gewinnorientiert im Interesse der
                    Mitarbeiter, der Kapitalgeber und des Staates
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="wrapper has-padding-bottom">
          <div className="bContainer orange">
            <div className="row">
              <h5>Swissness</h5>
              <p>
                Die Softwarelösungen der WigaSoft AG wurden und werden auch
                zukünftig vollständig in der Schweiz entwickelt, durch
                qualifizierte Mitarbeiter eingeführt und anschliessend betreut.
              </p>
            </div>
          </div>
        </section>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/portrait/">Über uns</a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default Portrait;
